<template>
  <component
    :is="tag"
    v-if="shouldDisplayRatings"
    class="flex bg-transparent"
    :class="{
      'flex-col items-start': areElementsStacked,
      'items-center': !areElementsStacked,
    }"
    data-test="product-page-reviews-count"
    @click="onRatingClick"
  >
    <RevRating :score="rating.averageRate" size="small" />
    <div
      :class="[
        'body-2-link underline-offset-2',
        {
          'no-underline': !anchorId,
          'ml-8': !areElementsStacked,
        },
      ]"
    >
      {{ i18n(translations.reviews, { count: rating.count }) }}
    </div>
  </component>
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'
import { computed } from 'vue'

import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevRating } from '@ds/components/Rating'

import { TRACKING_PAGE_TYPE_BY_ROUTE_NAME } from '~/constants/trackingPageType'

import translations from './Rating.translations'

type RatingInfo = {
  anchorId?: string
  rating: Rating
  areElementsStacked?: boolean
  trackingData?: Record<string, string>
}

const props = withDefaults(defineProps<RatingInfo>(), {
  anchorId: undefined,
  areElementsStacked: false,
  trackingData: () => ({}) as Record<string, string>,
})

const i18n = useI18n()
const route = useRoute()
const { trackClick } = useTracking()

const shouldDisplayRatings = computed(() => props.rating.count > 0)
const tag = computed(() => (props.anchorId ? RevButtonBase : 'div'))

function onRatingClick() {
  if (!props.anchorId) {
    return
  }

  const routeName = route.name?.toString() || ''

  trackClick({
    name: 'go_to_reviews',
    pageType: TRACKING_PAGE_TYPE_BY_ROUTE_NAME[routeName],
    value: props.rating,
    zone: 'detail',
    ...props.trackingData,
  })

  document.getElementById(props.anchorId)?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  })
}
</script>
